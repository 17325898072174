import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { useTranslation } from 'react-i18next';
import { getBusinessHours } from '../../../utils/businessHours';
import ArrowDownSVG from '../../../icons/arrow_down.svg';
import createRipple from '../../common/ripple';
import RestaurantEntryBusinessHours from './restaurantEntryBusinessHours';

const StyledInformationContainer = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  display: block;
  justify-content: space-between;
  padding: 20px 0;
  &.border-top {
    border-top: 1px solid ${({ theme }) => theme.colors.gray};
  }
`;
const StyledInformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;
const StyledInformationLabel = styled.span`
  color: ${({ theme }) => theme.colors.fontGray};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.71rem;
  margin: 0;
  &.red {
    color: ${({ theme }) => theme.colors.fontRed};
  }
`;
const StyledInformationData = styled(StyledInformationLabel)`
  color: ${({ theme }) => theme.colors.brandBlue};
  display: flex;
  justify-content: space-between;
  text-align: right;
  span {
    margin-right: 14px;
  }
  &.red {
    color: ${({ theme }) => theme.colors.fontRed};
  }
`;
const PosedIconSvgWrapper = posed.div({
	closed: {
		transform: 'rotate(0deg)',
	},
	open: {
		transform: 'rotate(180deg)',
	},
});
const StyledIconSvgWrapper = styled(PosedIconSvgWrapper)`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 11px;
  svg,
  svg * {
    fill: ${({ theme }) => theme.colors.brandPurple};
  }
`;
const TimetableWrapper = posed.div({
	closed: {
		height: 0,
		overflow: 'hidden',
	},
	open: {
		height: 'auto',
		overflow: 'hidden',
	},
});

const RestaurantEntryOpenTime = ({ businessHours }) => {
	const { t } = useTranslation();
	const [state, setState] = React.useState({
		timetableOpen: false,
	});
	const { openNow, openTime, closeTime } = getBusinessHours(businessHours);
	const openToday = !!openTime && !!closeTime;
	const { timetableOpen } = state;
	return (
		<StyledInformationContainer className="border-top">
			<StyledInformationWrapper
				className="ripple-dock"
				onClick={(e) => {
					createRipple(e);
					setState({
						timetableOpen: !timetableOpen,
					});
				}}
			>
				{!openToday && <StyledInformationLabel className="red">{t('restaurant.todayClosed')}</StyledInformationLabel>}
				{openToday && openNow && <StyledInformationLabel>{t('restaurant.openHours')}</StyledInformationLabel>}
				{openToday && !openNow && <StyledInformationLabel className="red">{t('restaurant.nowClosed')}</StyledInformationLabel>}
				&nbsp;
				<StyledInformationData>
					{openToday && (
						<span>
							{openTime.slice(0, 5)}
							{' '}
							-
							{closeTime.slice(0, 5)}
						</span>
					)}
					<StyledIconSvgWrapper pose={timetableOpen ? 'open' : 'closed'}>
						<ArrowDownSVG />
					</StyledIconSvgWrapper>
				</StyledInformationData>
			</StyledInformationWrapper>
			<TimetableWrapper pose={timetableOpen ? 'open' : 'closed'}>
				<RestaurantEntryBusinessHours businessHours={businessHours} />
			</TimetableWrapper>
		</StyledInformationContainer>
	);
};

export default RestaurantEntryOpenTime;
