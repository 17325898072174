import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Rater from 'react-rater';
import { useDispatch, useSelector } from 'react-redux';
import SEO from '../components/seo';
import { StyledRestaurantName } from '../components/restaurants/restaurantListItem';
import AppButton from '../components/common/button';
import { LINKS } from '../constants/links';
import RestaurantEntryOpenTime from '../components/restaurants/restaurantEntry/restaurantEntryOpenTime';
import { ImagesSlider } from '../components/common/imagesSlider';
import MarkerSVG from '../icons/map-marker.svg';
import CallAnswerSVG from '../icons/call-answer.svg';
import ClockSVG from '../icons/clock.svg';
import RestaurantSVG from '../icons/restaurant.svg';
import { isCameraError } from '../utils/cookies';
import 'react-rater/lib/react-rater.css';
import { clearLayoutAction, setLayoutAction } from '../state/layout.reducer';
import { fetchRestaurantAction } from '../state/restaurant.reducer';
import createRipple from '../components/common/ripple';
import { AppContainer } from '../components/common/appContainer';

const StyledRatingWrapper = styled(Link)`
	align-items: center;
	display: flex;
	font-size: 18px;
	padding: 14px 0;
	position: relative;
	text-decoration: none;
	width: 100%;
`;
const StyledRatingDescription = styled.span`
	color: ${({ theme }) => theme.colors.brandBlue};
	font-size: 11px;
	padding-left: 10px;
`;
const StyledAddress = styled.div`
	color: ${({ theme }) => theme.colors.fontGray};
	display: flex;
	font-size: 1rem;
	font-weight: 400;
	justify-content: space-between;
	line-height: 1.71rem;
	margin: 0;
`;
const StyledRestaurantDescription = styled.div`
	padding: 0 0 55px;
`;
const StyledInformationsWrapper = styled.div`
	padding: 25px 0;
`;
const StyledInformationWrapper = styled.div`
	align-items: flex-start;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
	display: flex;
	justify-content: space-between;
	padding: 20px 0;
	&.border-top {
		border-top: 1px solid ${({ theme }) => theme.colors.gray};
	}
`;
const StyledInformationLabel = styled(StyledAddress)`
	color: ${({ theme }) => theme.colors.fontGray};
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.71rem;
	margin: 0;
`;
const StyledInformationData = styled(StyledInformationLabel)`
	color: ${({ theme }) => theme.colors.brandBlue};
	display: flex;
	justify-content: space-between;
	position: relative;
	text-align: right;
	text-decoration: none;
`;
const StyledContactInformationsWrapper = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: column;
`;
const StyledDescriptionWrapper = styled(StyledInformationLabel)`
	margin: 0 0 50px;
`;
const StyledButtonWrapper = styled.div`
	text-align: center;
`;
const StyledIconSvgWrapper = styled.div`
	position: relative;
	text-align: right;
	width: 25px;
	svg,
	svg * {
		fill: ${({ theme }) => theme.colors.brandPurple};
		max-width: 12px;
	}
`;
const StyledRestaurantMenuLink = styled(Link)`
	color: ${({ theme }) => theme.colors.brandBlue};
	position: relative;
	text-decoration: none;
`;

const RestaurantPage = ({ pageContext }) => {
	const { t } = useTranslation();
	const restaurantReducer = useSelector((state) => state.restaurant);
	const dispatch = useDispatch();

	const { entry } = pageContext;
	const data = {
		...entry,
		...restaurantReducer.data,
	};

	useEffect(() => {
		dispatch(fetchRestaurantAction(entry.alternative_id));
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			setLayoutAction({
				backTo: LINKS.RESTAURANTS,
				customLogoSrc: data?.images?.find((image) => image.type === 'LOGO')?.url || null,
				customLogoUrl: data?.slug ? `${LINKS.RESTAURANT}/${data.slug}` : null,
			}),
		);

		return () => {
			dispatch(clearLayoutAction());
		};
	}, [dispatch, data]);

	const sliderImages = data?.images?.filter((image) => image.type === 'PROFILE');

	return (
		<>
			<SEO title={data.name} />
			<AppContainer>
				<ImagesSlider images={sliderImages} />
			</AppContainer>
			<StyledRestaurantDescription>
				<AppContainer>
					<StyledRatingWrapper
						onClick={createRipple}
						to={`/restaurant/${data.slug}/comments`}
					>
						<Rater
							interactive={false}
							rating={data.ratingsSum / data.ratingsCount}
							total={5}
						/>
						<StyledRatingDescription>
							{data.ratingsCount}
							{' '}
							{t('restaurant.rates')}
						</StyledRatingDescription>
					</StyledRatingWrapper>
					<StyledRestaurantName>{data.name}</StyledRestaurantName>
					<StyledAddress>
						<span>{data?.localization?.name}</span>
						<StyledIconSvgWrapper
							as="a"
							href={`https://www.google.com/maps/search/?api=1&query=${data?.localization?.lat},${data?.localization?.lng}`}
							onClick={createRipple}
							target="_blank"
						>
							<MarkerSVG />
						</StyledIconSvgWrapper>
					</StyledAddress>
					<StyledInformationsWrapper>
						<RestaurantEntryOpenTime businessHours={data.businessHours} />
						<StyledInformationWrapper>
							<StyledInformationLabel>{t('restaurant.contact')}</StyledInformationLabel>
							<StyledAddress>
								<StyledContactInformationsWrapper>
									<StyledInformationData
										as="a"
										href={`tel:${data.phoneNumber}`}
										onClick={createRipple}
									>
										{data.phoneNumber}
									</StyledInformationData>
									<StyledInformationData
										as="a"
										href={`mailto:${data.reservationEmail}`}
										onClick={createRipple}
									>
										{data.email}
									</StyledInformationData>
								</StyledContactInformationsWrapper>
								<StyledIconSvgWrapper>
									<CallAnswerSVG />
								</StyledIconSvgWrapper>
							</StyledAddress>
						</StyledInformationWrapper>
						{data.reservationEmail && (
							<StyledInformationWrapper>
								<StyledInformationLabel>
									{t('restaurant.reservation')}
									:
								</StyledInformationLabel>
								<StyledInformationData
									as="a"
									href={`mailto:${data.reservationEmail}`}
									onClick={createRipple}
								>
									<span>{data.reservationEmail}</span>
									<StyledIconSvgWrapper>
										<ClockSVG />
									</StyledIconSvgWrapper>
								</StyledInformationData>
							</StyledInformationWrapper>
						)}
						{data?.images?.find((image) => image.type === 'MENU') && (
							<StyledInformationWrapper>
								<StyledInformationLabel>
									{t('restaurant.menu')}
									:
								</StyledInformationLabel>
								<StyledInformationData>
									<StyledRestaurantMenuLink
										onClick={createRipple}
										to={`/restaurant/${data.slug}/menu`}
									>
										{t('restaurant.openMenu')}
									</StyledRestaurantMenuLink>
									<StyledIconSvgWrapper>
										<RestaurantSVG />
									</StyledIconSvgWrapper>
								</StyledInformationData>
							</StyledInformationWrapper>
						)}
					</StyledInformationsWrapper>
					<StyledDescriptionWrapper>{entry.description}</StyledDescriptionWrapper>
				</AppContainer>
				<StyledButtonWrapper>
					<AppButton
						as={Link}
						to={isCameraError() ? LINKS.ENTER_CODE : LINKS.SCAN_QR}
					>
						<span>{t('common.pay')}</span>
					</AppButton>
				</StyledButtonWrapper>
			</StyledRestaurantDescription>
		</>
	);
};

export default RestaurantPage;
