import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import AppText from '../../common/texts/appText';
import i18n from '../../../utils/i18n';

const StyledDaysWrapper = styled.div`
  padding: 20px 25px 0 20px;
`;
const StyledDayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledDayText = styled(AppText)`
  font-size: 0.9rem;
  line-height: 0.9rem;
  margin: 0.5rem 0;
`;

const daysTranslate = {
	de: {
		SUNDAY: 'Sonntag',
		MONDAY: 'Montag',
		TUESDAY: 'Dienstag',
		WEDNESDAY: 'Mittwoch',
		THURSDAY: 'Donnerstag',
		FRIDAY: 'Freitag',
		SATURDAY: 'Samstag',
	},
	en: {
		SUNDAY: 'Sunday',
		MONDAY: 'Monday',
		TUESDAY: 'Tuesday',
		WEDNESDAY: 'Wednesday',
		THURSDAY: 'Thursday',
		FRIDAY: 'Friday',
		SATURDAY: 'Saturday',
	},
	pl: {
		SUNDAY: 'Niedziela',
		MONDAY: 'Poniedziałek',
		TUESDAY: 'Wtorek',
		WEDNESDAY: 'Środa',
		THURSDAY: 'Czwartek',
		FRIDAY: 'Piątek',
		SATURDAY: 'Sobota',
	},
};

const BusinessHoursRow = ({ businessDay }) => {
	const { language } = i18n;
	return (
		<StyledDayWrapper>
			<StyledDayText>{daysTranslate[language.slice(0, 2)][businessDay.day]}</StyledDayText>
			<StyledDayText>
				{businessDay.openTime.slice(0, 5)}
				{' '}
				-
				{businessDay.closeTime.slice(0, 5)}
			</StyledDayText>
		</StyledDayWrapper>
	);
};

const RestaurantEntryBusinessHours = ({ businessHours }) => (
	<StyledDaysWrapper>
		{businessHours?.map((businessDay) => (
			<BusinessHoursRow
				key={uuid()}
				businessDay={businessDay}
			/>
		))}
	</StyledDaysWrapper>
);

export default RestaurantEntryBusinessHours;
